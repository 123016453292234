<script>
export default {
  name: 'Pagination',
  data: () => ({
    statusTypes: [
      {
        text: '已確認',
        value: 'CONFIRMED',
        color: '#2A5985',
        background: '#CCE6FF'
      },
      {
        text: '已下單',
        value: 'PLACED',
        color: '#6B5045',
        background: '#E6D9D1'
      },
      {
        text: '送遞中',
        value: 'OUT_FOR_DELIVERY',
        color: '#2A5985',
        background: '#CCE6FF'
      },
      {
        text: '已完成',
        value: 'COMPLETE',
        color: '#62D067',
        background: '#E5FFE2'
      },
      {
        text: '已取消',
        value: 'CANCELLED',
        color: '#C63221',
        background: '#FFDBCC'
      },
      {
        text: '門市待取',
        value: 'READY_FOR_PICKUP',
        color: '#2A5985',
        background: '#CCE6FF'
      },
      {
        text: '已退款',
        value: 'REFUNDED',
        color: '#F88922',
        background: '#FFF4E2'
      },
      {
        text: '未付款',
        value: 'PAYMENT_INCOMPLETE',
        color: '#F88922',
        background: '#FFF4E2'
      }
    ]
  }),
  props: {
    status: {
      required: true
    }
  },
  computed: {
    item () {
      const statusType = this.statusTypes.find((s) => s.value === this.status)
      if (!statusType) {
        return this.statusTypes[0]
      }
      return statusType
    }
  }
}
</script>
<template>
  <div class="d-flex rounded-sm justify-content-center align-items-center">
    <div
      :style="{
        backgroundColor: item.background,
        maxWidth: 'fit-content',
        padding: '4px 12px',
      }"
      class="d-flex rounded-sm justify-content-center align-items-center"
      style="gap: 10px"
    >
      <div
        style="width: 6px; height: 6px; border-radius: 2px"
        :style="{ backgroundColor: item.color }"
      ></div>
      <span :style="{ color: item.color }">{{ item.text }}</span>
    </div>
  </div>
</template>
