<template>
  <b-container fluid>
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <router-link
        class="text-brown text-decoration-none mr-3"
        to="/user-management"
      >
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb bg-transparent p-0 mb-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/user-management">使用者管理</router-link>
        </li>
        <li class="breadcrumb-item">優惠券</li>
      </ol>
    </nav>
    <b-row class="mb-4">
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="searchQuery"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索"
          />
        </div>
      </b-col>
      <b-col cols="12" md="3" xl="2" class="mb-2">
        <b-form-select
          style="color: #bec0d0"
          id="paymentMethod"
          class="form-control--h46px rounded-10px"
          name="paymentMethod"
          v-model="filters.paymentMethod"
          :options="paymentOptions"
        ></b-form-select>
      </b-col>
      <b-col cols="12" md="3" xl="2" class="mb-2">
        <b-form-select
          style="color: #bec0d0"
          id="paymentMethod"
          class="form-control--h46px rounded-10px"
          name="paymentMethod"
          v-model="filters.status"
          :options="statusTypes"
        ></b-form-select>
        </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          class="b-form--datepicker ml-4 form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-orderFulFilmentMethod="{ item }">
        <span v-if="item.orderFulFilmentMethod.toUpperCase() === 'DELIVERY'"
          >送貨</span
        >
        <span v-else>門店自取</span>
      </template>
      <template v-slot:custom-status="{ item }">
        <StatusButton :status="item.status"></StatusButton>
      </template>
      <template v-slot:custom-createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:custom-paymentMethod="{ item }">
        {{ item.paymentMethod.toUpperCase() }}
      </template>
      <template v-slot:custom-reference="{ item }">
        <div
          class="text-brown"
          v-if="item.paymentMethod.toUpperCase() === 'FPS'"
          @click="showFpsEvidenceModal(item.reference)"
          style="text-decoration: underline; cursor: pointer"
        >
          查看
        </div>
      </template>
      <template v-slot:custom-action="{ item }">
        <b-dropdown
          boundary="viewport"
          variant="link"
          right
          toggle-class="text-white text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon
              icon="three-dots"
              font-scale="1.5"
              class="text-dark"
            ></b-icon>
          </template>
          <b-dropdown-item @click="showDetailModal(item.orderId)">
            看詳情</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </TableData>
    <Pagination
      v-if="filterOrders.length && !isLoading"
      v-model="currentPage"
      :list-data="filterOrders"
      :limit="limit"
    />

    <b-modal
      ref="fpsEvidenceModal"
      title="FPS 參考"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <div>
        <img
          :src="uploadedReference"
          :alt="uploadedReference"
          width="100%"
          style="min-width: 360px"
        />
      </div>
    </b-modal>

    <b-modal
      ref="viewDetailModal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      title="訂單詳情"
    >
      <div
        v-if="Object.keys(orderDetails).length"
        class="card table-responsive border-0 mb-4 px-3"
      >
        <div
          class="d-flex my-2 align-items-center"
          style="gap: 16px; justify-content: space-between"
        >
          <h6 class="self-items-center">
            訂單編號#: {{ orderDetails.orderId }}
          </h6>
          <StatusButton :status="orderDetails.status"></StatusButton>
        </div>
        <div>訂單日期: {{thisFormatIOSDate(orderDetails.createdDate) }}</div>
        <div>
          付款方式:
          <span style="text-transform: uppercase">
            {{ orderDetails.paymentMethod }}</span
          >
        </div>
        <div v-if="orderDetails.paymentMethod.toUpperCase() !== 'FPS'">
          Payment intent:
          <span style="font-weight: 500;">
            {{ orderDetails.paymentIntent }}</span
          >
        </div>
        <div class="table-responsive mb-0 mt-2">
          <table class="table table-hover">
            <thead class="bg-brown--light">
              <tr>
                <th class="text-nowrap">產品</th>
                <th class="text-nowrap text-center">單價 (HKD)</th>
                <th class="text-nowrap text-center">數量</th>
                <th
                  class="text-nowrap text-center"
                  v-if="orderDetails.refundedAmount"
                >
                  移除數量
                </th>
                <th class="text-nowrap text-center">折扣 (HKD)</th>
                <th class="text-nowrap text-center">優惠券 (HKD)</th>
                <th class="text-nowrap text-center">總計 (HKD)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderDetails.orderItems" :key="item.id">
                <td>
                  <div
                    class="d-flex align-items-center"
                    style="gap: 16px; max-width: 220px"
                  >
                    <img
                      v-if="!!item.productImages.length"
                      :src="item.productImages[0]"
                      width="50px"
                      height="50"
                      style="border-radius: 5px"
                    />
                    <div style="overflow-x: hidden; flex: 1 1 0%">
                      <div style="font-weight: 500;">{{ item.productNameZhHk }}</div>
                      <div>產品編號: {{ item.posId }}</div>
                      <div v-if="item.productOptionViewDTOs.length">
                        <p
                          v-for="option in item.productOptionViewDTOs"
                          :key="option.productOptionId"
                          style="margin: 0;"
                        >
                          選項: {{ option.optionValue }}
                        </p>
                      </div>
                      <div>備註: {{ item.remarks }}</div>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center">{{ item.price }}</td>
                <td class="align-middle text-center">{{ item.quantity }}</td>
                <td
                  class="align-middle text-center"
                  v-if="orderDetails.refundedAmount"
                >
                  <span v-if="item.refundDetails"
                    >-{{ item.refundDetails.quantity }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">{{ item.discount }}</td>
                <td class="align-middle text-center">
                  {{ item.couponDiscount }}
                </td>
                <td class="align-middle text-center">
                  {{ item.finalOrderItemAmount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="align-middle text-nowrap" colspan="4"></td>
                <td colspan="3" class="align-middle text-center">
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">小計</div>
                    <div style="width: 50%" class="text-right">
                      HKD
                      {{
                        orderDetails.orderPricingDTO.originalAmount.toLocaleString()
                      }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">快遞費</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.deliveryFee.toLocaleString() }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">總折扣</div>
                    <div style="width: 50%" class="text-right">
                      -HKD
                      {{
                        orderDetails.orderPricingDTO.totalDiscount.toLocaleString()
                      }}
                    </div>
                  </div>
                  <hr />
                  <div
                    class="d-flex align-items-center"
                    style="font-weight: 600"
                  >
                    <div style="width: 50%" class="text-left">總計</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.finalAmount.toLocaleString() }}
                    </div>
                  </div>
                  <template
                    v-if="
                      orderDetails.refundedAmount || orderDetails.refundMethod
                    "
                  >
                    <hr />
                    <h6 class="text-left">退款詳情</h6>
                    <div
                      class="d-flex align-items-center text-danger mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款金額</div>
                      <div style="width: 50%" class="text-right">
                        HKD {{ orderDetails.refundedAmount.toLocaleString() }}
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款方式</div>
                      <div style="width: 50%" class="text-right">
                        {{ orderDetails.refundMethod }}
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <section v-if="orderDetails.orderFulFilmentMethod.toUpperCase() !== 'PICKUP'">
          <hr class="mt-4" />
            <div>
              <h6>收件人資料</h6>
              <b-row>
                <b-col cols="3">顧客姓名:</b-col>
                <b-col cols="9">{{ orderDetails.customerName }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{ orderDetails.phoneNumber }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">收件地址:</b-col>
                <b-col cols="9">{{ orderDetails.address }}</b-col>
              </b-row>
            </div>
          <template
            v-if="
              orderDetails.driverName ||
              orderDetails.driverPhone ||
              orderDetails.driverTracking
            "
          >
            <hr class="mt-4" />
            <div>
              <h6>運送資訊</h6>
              <b-row v-if="orderDetails.driverName">
                <b-col cols="3">司機姓名:</b-col>
                <b-col cols="9">{{ orderDetails.driverName }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.driverPhone">
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{ orderDetails.driverPhone }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.driverTracking">
                <b-col cols="3">Courier Tracking URL:</b-col>
                <b-col cols="9">{{ orderDetails.driverTracking }}</b-col>
              </b-row>
            </div>
          </template>
        </section>
        <section v-else>
          <template
            v-if="
              !!orderDetails.orderPickupViewDTO &&
              Object.keys(orderDetails.orderPickupViewDTO).length
            "
          >
            <hr class="mt-4" />
            <div>
              <h6>收件人資料</h6>
              <b-row v-if="orderDetails.orderPickupViewDTO.customerName">
                <b-col cols="3">顧客姓名:</b-col>
                <b-col cols="9"
                  >{{ orderDetails.orderPickupViewDTO.title }}
                  {{ orderDetails.orderPickupViewDTO.customerName }}</b-col
                >
              </b-row>
              <b-row v-if="orderDetails.orderPickupViewDTO.email">
                <b-col cols="3">電子郵件:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.email
                }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.orderPickupViewDTO.phone">
                <b-col cols="3">手機號碼:</b-col>
                <b-col cols="9">
                  {{ orderDetails.orderPickupViewDTO.countryCode
                  }}{{ orderDetails.orderPickupViewDTO.phone }}
                </b-col>
              </b-row>
            </div>
            <hr class="mt-4" />
            <div v-if="orderDetails.orderPickupViewDTO.branch">
              <h6>取貨資訊</h6>
              <b-row>
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.phone
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">店家名稱:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.nameZhHk
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">地址:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.addressZhHk
                }}</b-col>
              </b-row>
            </div>
          </template>
        </section>
        <hr class="mt-4" />
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import Pagination from '../../components/Pagination.vue'
import TableData from '../../components/TableData.vue'
import customerService from '../../services/customer.service'
import StatusButton from '../../components/StatusButton.vue'
import { filterByDate, formatIOSDate } from '../../utils'
import orderService from '../../services/order.service'
export default {
  name: 'OrderHistory',
  data: () => ({
    orders: [],
    currentPage: 1,
    limit: 10,
    searchQuery: '',
    statusTypes: [
      { value: null, text: '狀態' },
      {
        text: '已下單',
        value: 'PLACED'
      },
      {
        text: '已確認',
        value: 'CONFIRMED'
      },
      {
        text: '未付款',
        value: 'PAYMENT_INCOMPLETE'
      },
      {
        text: '送遞中',
        value: 'OUT_FOR_DELIVERY'
      },
      {
        text: '門市待取',
        value: 'READY_FOR_PICKUP'
      },
      {
        text: '已完成',
        value: 'COMPLETE'
      },
      {
        text: '已取消',
        value: 'CANCELLED'
      },
      {
        text: '已退款',
        value: 'REFUNDED'
      }
    ],
    uploadedReference: '',
    headers: [
      { text: '訂單編號', value: 'orderId' },
      { text: '顧客姓名', value: 'customerName' },
      { text: '電話號碼', value: 'phoneNumber' },
      { text: '訂單履行方式', value: 'orderFulFilmentMethod' },
      { text: '地址', value: 'address', class: 'text-normal text-start' },
      { text: '狀態', value: 'status' },
      { text: '付款方式', value: 'paymentMethod' },
      { text: '參考', value: 'reference' },
      { text: '創建日期', value: 'createdDate' },
      { text: '操作', value: 'action' }
    ],
    isLoading: true,
    paymentOptions: [
      { value: null, text: '付款方式' },
      { value: 'FPS', text: 'FPS' },
      { value: 'STRIPE', text: 'STRIPE' }
    ],
    filters: {
      start: '',
      end: '',
      paymentMethod: null,
      status: null
    },
    orderDetails: {},
    refundDetails: null,
    selectedId: null

  }),
  components: {
    Pagination,
    TableData,
    StatusButton
  },
  mounted () {
    this.getCustomerAllOrderHistory()
  },
  computed: {
    filterOrders () {
      return this.orders
        .filter((item) =>
          this.searchQuery.length
            ? item?.customerName
              ?.toLowerCase()
              ?.replace(/\s+/g, '')
              ?.includes(
                this.searchQuery.toLowerCase().replace(/\s+/g, '')
              ) ||
              item?.orderId
                .toString()
                ?.toLowerCase()
                ?.replace(/\s+/g, '')
                ?.includes(this.searchQuery.toLowerCase().replace(/\s+/g, ''))
            : true
        )
        .filter((item) =>
          this.filters.paymentMethod?.length
            ? item.paymentMethod.toUpperCase() === this.filters.paymentMethod
            : true
        )
        .filter((item) =>
          this.filters.status?.length
            ? item.status.toUpperCase() === this.filters.status
            : true
        )
        .filter((item) =>
          filterByDate(
            item.createdDate,
            this.filters.start,
            this.filters.end
          )
        )
    },
    paginatedData () {
      return this.filterOrders.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterOrders () {
      this.currentPage = 1
    }
  },
  methods: {
    async getCustomerAllOrderHistory () {
      this.isLoading = true
      await customerService.getCustomerOrderHistory(this.$route.params.id).then(
        (res) => {
          this.orders = res.data
          console.log(res.data)
        },
        (err) => {
          console.log(err)
          this.isLoading = false
        }
      )
      this.isLoading = false
    },
    showFpsEvidenceModal (reference) {
      this.uploadedReference = reference
      this.$refs.fpsEvidenceModal.show()
    },
    hideFpsEvidenceModal () {
      this.$refs.fpsEvidenceModal.hide()
      this.uploadedReference = null
    },
    thisFormatIOSDate (date) {
      formatIOSDate(date)
    },
    deepClone (obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }

      const clone = Array.isArray(obj) ? [] : {}

      for (const key in obj) {
        const value = obj[key]
        if (key === 'quantity') {
          clone[key] = null
        } else {
          clone[key] = this.deepClone(value)
        }
      }

      return clone
    },
    async showDetailModal (id) {
      this.selectedId = id
      this.getOrderDetails(id)
      this.$refs.viewDetailModal.show()
    },
    hideDetailModal () {
      this.$refs.viewDetailModal.hide()
      this.selectedStatus = null
    },
    async getOrderDetails (id) {
      this.orderDetails = {}
      this.orderDetails = await orderService.getOrderDetails(id)
      this.refundItems = this.deepClone(this.orderDetails.orderItems)
      if (this.orderDetails.refundedAmount) {
        this.refundDetails = await orderService.getRefundDetails(id)
        this.orderDetails = {
          ...this.orderDetails,
          orderItems: this.orderDetails.orderItems.map((el) => ({
            ...el,
            refundDetails: this.refundDetails.find((s) => s.productId === el.productId)
          }))
        }
      }
      if (this.orderDetails.orderFulFilmentMethod === 'PICKUP') {
        const branchDetails = await orderService.getBranchDetails(this.orderDetails.orderPickupViewDTO.storeId)
        this.orderDetails.orderPickupViewDTO = {
          ...this.orderDetails.orderPickupViewDTO,
          branch: branchDetails
        }
      }
    }
  }
}
</script>
